import {
  module as BaseModule
} from 'modujs';

export default class extends BaseModule {
  constructor(m) {
      super(m);
  }

  init() {
      let tabBtns = document.querySelectorAll(".c-translate__button");
      let tabContents = document.querySelectorAll(".trans");

      if (tabBtns) {


          for (let i = 0; i < tabBtns.length; i++) {
              tabBtns[i].onclick = function (e) {
                  let id = tabBtns[i].dataset.target;

                  if (id) {
                      // remove active from other buttons

                      tabBtns.forEach((btn) => {
                          btn.classList.remove('-active');
                          tabBtns[i].classList.add('-active');
                      });
                      // hide other tabContents
                      tabContents.forEach((content) => {
                          
                          content.classList.remove("active");
                      });

                      const currentContent = document.getElementById(id);
                      currentContent.classList.add("active");

                      if (id === 'maori') {
                        console.log('huh');
                        document.querySelector('#maori .l-layout__content-text').innerHTML = 'He ohia ngāwari e noho nei hei tūāpapa mō tā mātou rautaki: <strong>ki te whakatinanahia ā mātou uara, ka angitu</strong>. Mā te ū ki ēnei mātāpono e whirinaki mai ai ngā hapori.';
                      }
                  }
                  
              };
          }



      }


  }
}